import { SystemProps, Flex, Box, css, Stack, Grid } from "@storyofams/react-ui";

import { Heading } from "../../Heading";
import { Image } from "../../Image";
import { Link } from "../../Link";
import { HoverArea, ScaleOnHover } from "../../ScaleOnHover";
import { Slider } from "../../Slider";

type CategoriesProps = {
  first?: boolean;
  block?: boolean;
  content: {
    title?: string;
    categories?: any;
  };
} & SystemProps;

const getBg = (name) => {
  switch (name) {
    case "Draagoliën":
      return "#EAC8B5";
    case "Geuroliën":
      return "#E6E1D7";
    case "Hydrosolen":
      return "#DBE4DB";
    case "(Basis) Zepen":
      return "#EED2CB";
    case "Klei & Poeders":
      return "#DECEBB";
    case "Kruiden & Plantpoeders":
      return "#D8DCC0";
    case "Boters & Wassen":
      return "#EDD7B7";
    case "Hulpstoffen":
      return "#D8CACA";
    default:
      return "#F1E3D5";
  }
};

export const Categories = ({
  content,
  first,
  block,
  ...props
}: CategoriesProps) => {
  return (
    <Flex
      flexDirection="column"
      textAlign="center"
      css={css({
        width: "100%",
        ".swiper-slide": {
          width: ["136px", "282px"],
        },
        ".swiper-button-next, .swiper-button-prev": {
          display: "none",
        },
      })}
      {...props}
    >
      {!!content?.title && (
        <Heading
          width="260px"
          as={first ? "h1" : "h2"}
          variant="h3"
          fontWeight="bold"
          mb={[4, 5]}
        >
          {content?.title}
        </Heading>
      )}
      <Grid rowSize={[2, 4]} columnGap={3} rowGap={4}>
        {content?.categories[0]?.title &&
          content?.categories
            ?.filter(Boolean)
            ?.map(({ id, title, image, handle }) => (
              <Link href={`/collections/${handle}`} key={id}>
                <Box display="block" as="a">
                  <HoverArea>
                    <Box
                      p={3}
                      bg={getBg(title)}
                      borderRadius="md"
                      minHeight={["100px", "200px"]}
                      display="flex"
                      alignItems="center"
                    >
                      <ScaleOnHover>
                        <Image
                          position="relative"
                          // sizes="(max-width: 767px) 88px, 234px"
                          // height={[64, 160]}
                          width="100%"
                          layout="fill"
                          objectFit="contain"
                          quality={90}
                          src={image?.originalSrc}
                          alt={image?.altText || title}
                        />
                      </ScaleOnHover>
                    </Box>
                    <Heading
                      as="h3"
                      variant="h5"
                      fontWeight="medium"
                      mt={[2, 3]}
                    >
                      {title}
                    </Heading>
                  </HoverArea>
                </Box>
              </Link>
            ))}
      </Grid>
    </Flex>
  );
};
