import { ReactNode } from "react";
import { Box, SystemProps } from "@storyofams/react-ui";
import { motion } from "framer-motion";

const MotionBox = motion(Box);

export const HoverArea = ({
  children,
  ...props
}: { children: ReactNode } & SystemProps) => (
  <MotionBox width="100%" flexDirection="column" whileHover="hover" {...props}>
    {children}
  </MotionBox>
);

export const ScaleOnHover = ({ children }: { children: ReactNode }) => (
  <MotionBox
    position="relative"
    width="100%"
    height="100%"
    variants={{
      initial: {
        scale: 1,
      },
      hover: { scale: 1.1 },
    }}
    transition={{ ease: [0.25, 1, 0.5, 1], duration: 0.7 }}
  >
    {children}
  </MotionBox>
);
